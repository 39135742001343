<template>
    <select
        v-bind="$attrs"
        v-model="model"
        class="block opacity-100 w-full rounded-md border-0 py-1.5 shadow-sm text-gray-900 ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6
        dark:text-gray-100 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-primary disabled:cursor-not-allowed disabled:bg-gray-50 disabled:dark:bg-gray-800 disabled:text-gray-500
        disabled:dark:text-gray-400 disabled:ring-gray-200 disabled:dark:ring-gray-400 disabled:dark:border-gray-300">
        <slot />
    </select>
</template>

<script setup>
const props = defineProps({
    modelValue: {
        required: false,
        type: [Boolean, String, Number, Object, Array],
        default: null
    }
})

const emits = defineEmits(['update:modelValue'])

const model = computed({
    get: () => props.modelValue,
    set: (value) => emits('update:modelValue', value)
})

</script>
